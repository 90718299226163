var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { borderRadius, borderTop, color } from 'styled-system';
import { getForwardPropOpts } from '../../theme/helpers/forward-props';
import { boxSizing } from '../../theme/system-utilities';
import { Flex } from '../Flex';
export var GradientBlockType;
(function (GradientBlockType) {
    GradientBlockType["Horizontal"] = "to right";
    GradientBlockType["Vertical"] = "to bottom";
})(GradientBlockType || (GradientBlockType = {}));
var GradientBlock = styled(Flex, getForwardPropOpts(['color', 'type']))(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n  ", "\n"])), boxSizing, color, borderTop, borderRadius, function (_a) {
    var startColor = _a.startColor, endColor = _a.endColor, type = _a.type;
    return startColor &&
        endColor &&
        "background-image: linear-gradient(".concat(type, ", ").concat(startColor, ", ").concat(endColor, ");");
}, function (_a) {
    var startColor = _a.startColor;
    return startColor && "background-color: ".concat(startColor, ";");
});
GradientBlock.defaultProps = {
    type: GradientBlockType.Horizontal,
    boxSizing: 'border-box',
    width: '100%',
    bg: 'sys.neutral.background.weaker',
};
export default GradientBlock;
var templateObject_1;
